// formパラメータ操作型

$(function () {
  //=======
  //検索時、空のパラメータ削除(formタグにつけるもの)
  //=======
  $(".js--side-form").on("formdata", (e) => {
    let fd = e.originalEvent.formData;
    let deleteKeys = [];
    for (let value of fd.entries()) {
      // 値が空の場合は、その空のキーを取得
      if (!value[1]) deleteKeys.push(value[0]);
    }
    for (let value of deleteKeys) {
      // 値が空のキーを削除
      fd.delete(value);
    }
    if (fd.has("keyword_pattern") && !fd.has("keyword") && !fd.has("keyword2")) {
      fd.delete("keyword_pattern");
    }
  });
});
