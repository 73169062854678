// 検索-都道府県選択時、対応する市区町村一覧を取得・生成

$(function () {
  window.addEventListener("load", makeCityList);

  $(".js-prefecture-select").on("change", makeCityList);
});

function makeCityList() {
  var prefCd = $(".js-prefecture-select").val();
  if (prefCd == null || prefCd == "") {
    $(".js-city-select").val("");
    $(".js-city-select").prop("disabled", true);
    $(".js-city-select").parents(".input-select-front").addClass("is-disabled");
  } else {
    $.getJSON("/home/make_city_list?prefCd=" + prefCd, (json) => {
      // 市区町村セレクトボックスのクリア
      $(".js-city-select").empty();
      // 市区町村セレクトボックスの生成
      var append = "<option value>選択してください</option>";
      for (let i = 0; i < json["data"].length; i++) {
        append += '<option value="' + json["data"][i]["id"] + '" >';
        append += json["data"][i]["name"];
        append += "</option>";
      }
      $(".js-city-select").append(append);
      // セレクトボックスの有効化
      $(".js-city-select").prop("disabled", false);
      $(".js-city-select").parents(".input-select-front").removeClass("is-disabled");
    });
  }
}
